import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
// import Sidebar from './sidebar';
import Footer from './footer';
import Sidebars from './sidebars';

const PrivateLayout = () => {
    return (
        <div id="layout-wrapper">

            <Header />

            <Sidebars />
            <div className="vertical-overlay"></div>
            <div className="main-content">
                <div className="page-content">
                    <Outlet />
                </div>
                <Footer />
            </div>


        </div>
    );
}

export default PrivateLayout;
