import Constant from "./constant";

const appConstant = { ...Constant };

export const isUserLoggedIn = () =>
    localStorage.getItem(appConstant.storageUserDataKeyName);

export const saveUserData = (data) =>{

    localStorage.setItem(appConstant.storageUserDataKeyName, data);
}

export const delUserData = () =>
    localStorage.removeItem(appConstant.storageUserDataKeyName);

export const getUserData = () =>
    JSON.parse(localStorage.getItem(appConstant.storageUserDataKeyName));

export const getUserRole = () => {
    const user = getUserData();
    // console.log(user);
    if (user?.user) {
        return user?.user?.resource_access?.oni?.roles[0];
    }
    return null;
};
export const getOperateurRole = () =>{
        const role ='operateur'
        return role;
    
}
export const getFinanceRole = () =>{
        const role ='finance'
        return role;
    
}
export const getConsultationRole = () =>{
        const role ='consultation'
        return role;
    
}
export const getAdminRole = () =>{
        const role ='administrateur'
        return role;
    
}
export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, "");

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to =
        "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        // Collapse dashes
        .replace(/-+/g, "-");

    return str;
};

export const getDateTime = (hasComplet = false, separator = "-") => {
    const formate = (number) => {
        return number <= 9 ? `0${number}` : number;
    };
    const tempDate = new Date();
    const date =
        tempDate.getFullYear() +
        separator +
        formate(tempDate.getMonth() + 1) +
        separator +
        formate(tempDate.getDate());
    const hour =
        tempDate.getHours() +
        ":" +
        tempDate.getMinutes() +
        ":" +
        tempDate.getSeconds();
    const currentDate = hasComplet ? date + " " + hour : date;
    return currentDate;
};
export const getStatusWithColor=(status)=> {
 
    switch (status) {
      case "ATTENTE":
        return <span className="badge text-uppercase badge-soft-warning"> En attente</span>;
      case "EXAMEN":
        return <span className="badge text-uppercase badge-soft-info"> En examen </span>;
      case "ACCEPTER":
        return <span className="badge text-uppercase badge-soft-secondary"> Acceptée </span>;
      case "VALIDER":
        return <span className="badge text-uppercase badge-soft-success"> Validée </span>;
      case "TRAITER":
        return <span className="badge text-uppercase badge-soft-success"> Traitée </span>;
      case "MODIFIER":
        return <span className="badge text-uppercase badge-soft-secondary"> Modifiée </span>;
      case "REJETER_PARTIEL":
        return <span className="badge text-uppercase badge-soft-warning"> REJET PARTIEL </span>;
      case "REJETER":
        return <span className="badge text-uppercase badge-soft-danger"> Rejetée </span>;
      default:
        return '';
    }
  
  }
  export const TypeAcces=(status)=> {
   
    switch (status) {
      case "ACCES_PLATEFORME":
        return 'ACCES PLATEFORME';
      case "ACCES_API":
        return 'ACCES API';
      
      default:
        return '';
    }
    }