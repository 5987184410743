import React from "react";
import ApiRoute from "../utility/ApiRoute";
import API_BASIC, { API_BASIC_PASSWORD, API_BASIC_USER } from "../utility/ApiService";
import { delUserData, saveUserData } from "../utility/Utils";


class AuthService {
    async ChangePassword(data) {

        try {
            const response = await API_BASIC_PASSWORD.put(ApiRoute.changePasse + '?id=' + data?.id, {
                currentPassword: data?.oldpasswordInput,
                newPassword: data?.newpasswordInput
            });
            return response;
        } catch (error) {
            console.log("Erreur lors de la récupération des demandes:", error);
            throw error;
        }
    }

    login(email, password) {
        return API_BASIC.post(ApiRoute.login, {
            email,
            password,
        }).then((response) => {
            const data = response?.data;
            if (data?.data?.access_token) {
                saveUserData(JSON.stringify(data.data));
            }
            return data;
        });
    }
    updateProfil(data) {
        return API_BASIC.patch(ApiRoute.userProfil + "/" + data.id, {
            id: data.id,
            nom: data.nom,
            email: data.email,
            telephone: data.telephone,
        }).then((response) => {
            const data = response?.data.data;

            return data;
        });
    }

    async logout(data) {
        try {
            const response = await API_BASIC_USER.post(ApiRoute.logout + '?username=' + data);
            // return response;
            console.log('logout', response.status);
            if (response?.status===200) {
                delUserData();
                window.location.reload();
                window.location.href = '/';
            }
        } catch (error) {
            console.log("Erreur lors de la récupération des demandes:", error);
            throw error;
        }


    }
}

export default new AuthService();
