import React from "react";
import constant from "../utility/constant";
import { all } from "axios";
const OniLogin = React.lazy(() => import("../pages/common"));
const  DashboardOni= React.lazy(() => import("../pages/private"));
const  demandeAttente= React.lazy(() => import("../pages/private/demandes/attente"));
const  demandeExamen= React.lazy(() => import("../pages/private/demandes/examen"));
const  demandePaiement= React.lazy(() => import("../pages/private/demandes/paiement"));
const  demandeValide= React.lazy(() => import("../pages/private/demandes/valide"));
const  demandeTraite= React.lazy(() => import("../pages/private/demandes/traite"));
const  demandeShow= React.lazy(() => import("../pages/private/demandes/show"));
const  Privileges= React.lazy(() => import("../pages/private/privileges"));
const  Categories= React.lazy(() => import("../pages/private/categories"));
const  Configurations= React.lazy(() => import("../pages/private/configuration"));
const  Users= React.lazy(() => import("../pages/private/users"));
const  UsersShow= React.lazy(() => import("../pages/private/users/show"));
const  Tracabilite= React.lazy(() => import("../pages/private/tracabilite"));
const  TracabiliteShow= React.lazy(() => import("../pages/private/tracabilite/show"));
const  Frais= React.lazy(() => import("../pages/private/frais"));
const  Gestionnaire= React.lazy(() => import("../pages/private/users/gestionnaire"));
const  GestionnaireShow= React.lazy(() => import("../pages/private/users/gestionaireshow"));
const  Droit= React.lazy(() => import("../pages/private/droit"));
const  Commission= React.lazy(() => import("../pages/private/demandes/commission"));
const  Profile= React.lazy(() => import("../pages/private/profile/profile"));
const  AvisCil= React.lazy(() => import("../pages/private/demandes/aviscil"));
const  AvisDg= React.lazy(() => import("../pages/private/demandes/avisdg"));
const  Alldemande= React.lazy(() => import( "../pages/private/demandes/all"));
const  Statistique= React.lazy(() => import( "../pages/private/statistique"));


const appConstant = { ...constant };
const role = { ...appConstant?.appRole };

const privateRoutes =[
   
    {
        path: "admin/dashboard",
        component: DashboardOni,
        permissions:["all"],
        
    },
    {
        path: "admin/gestionnaires",
        component: Gestionnaire,
        permissions:['all'],
        
    },
    {
        path: "admin/demandes",
        component: Alldemande,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/en-attentes",
        component: demandeAttente,
        permissions:["all"],
        
    },
    
    {
        path: "admin/demandes/en-examen",
        component: demandeExamen,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/en-attente-paiement",
        component: demandePaiement,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/validees",
        component: demandeValide,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/attente-avis-commission",
        component: Commission,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/attente-decision-dg",
        component: AvisDg,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/attente-avis-cil",
        component: AvisCil,
        permissions:["all"],
        
    },
    {
        path: "admin/demandes/traitee",
        component: demandeTraite,
        permissions:["all"],
        
    },
    {
        path: "admin/demande/show",
        component: demandeShow,
        permissions:["all"],
        
    },
    {
        path: "/admin/privileges",
        component: Privileges,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/categories",
        component: Categories,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/configuration",
        component: Configurations,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/compteOperations",
        component: Users,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/compteOperations/show",
        component: UsersShow,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/gestionnaire/show",
        component: GestionnaireShow,
        permissions:['all'],
        
    },
  

    {
        path: "/admin/tracabilite",
        component: Tracabilite,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/tracabilite/show",
        component: TracabiliteShow,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/frais-prestation",
        component: Frais,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/droit-config",
        component: Droit,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/statistiques",
        component: Statistique,
        permissions:[role?.admin],
        
    },
    {
        path: "/admin/profile",
        component: Profile,
        permissions:['all'],
        
    },

   
   
];

const publicRoutes =[
    {
        path: "login",
        component: OniLogin,
        permissions:["all"],
    },
];

export { publicRoutes, privateRoutes };
