const BACK_ENPOINT = 'api/v1'
const BACK_ENPOINT_USER = 'oni/api/v1'
export const DOMAIN_DEMANDE = 'https://oni-api-demandes.switch-maker.net'
export const DOMAIN_USER = 'https://oni-api-users.switch-maker.net'
export const DOMAIN_CONSULTATION = 'https://oni-api-consultations.switch-maker.net'


// export const DOMAIN_DEMANDE = 'http://192.168.2.108:8085'
// export const DOMAIN_USER = 'http://192.168.2.108:8086'
// export const DOMAIN_CONSULTATION = 'http://192.168.2.108:8084'

export default {
    url: `${DOMAIN_DEMANDE}/${BACK_ENPOINT}`,
    url_user: `${DOMAIN_USER}/${BACK_ENPOINT_USER}`,
    url_trace: `${DOMAIN_USER}/${BACK_ENPOINT}`,
    url_consultation: `${DOMAIN_CONSULTATION}/${BACK_ENPOINT}`,
    url_password: `${DOMAIN_USER}`,
    mediaUrl: `${DOMAIN_DEMANDE}`,
    tokenType: 'Bearer',
    storageUserDataKeyName: 'USER-DATA',
    appRole: {
        admin: 'administrateur',
        operateur:'operateur',
        all:'all'
    }
    
}