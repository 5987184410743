import React from "react";
import { getUserData } from "../utility/Utils";
import { Navigate, useNavigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  let navigate = useNavigate();

  const authed = getUserData();
  if (!authed) {
    return <Navigate to="/" replace />;
  } else {

   
      return children;
    
  }




  // return authed!=null ? !authed?.user?.mdpDefaut===true ? (children): <Navigate to="/admin/profile" replace />  : (<Navigate to="/" replace />);

  // return authed!=null ? (children) : (<Navigate to="/" replace />);
}

export default RequireAuth;
