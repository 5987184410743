import React from "react";
const Footer = () => {
    const currentDate = new Date().getFullYear()
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        © ONI {currentDate}
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            Tous droit reservé
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer
