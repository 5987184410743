import React, { useEffect, useState } from "react"
import { getAdminRole, getUserData, getUserRole } from "../../utility/Utils"
import { Dropdown } from "react-bootstrap"
import AuthService from "../../services/authService"
import logo from '../../assets/images/oni-logo1.jpeg'
// import"../../assets/back/libs/jsvectormap/css/jsvectormap.min.css";
// import"../../assets/back/libs/swiper/swiper-bundle.min.css";

const Header = () => {
    const role = getUserRole()
    const adminRole =getAdminRole()
    const [userProfile, setUserProfile] = useState({})
    const [url, setUrl] = useState({})
    const user = getUserData()
        const logout=()=> {
            AuthService.logout(user?.user?.preferred_username);
        }
        useEffect(() => {
            setUrl(window.location.pathname.replace('/admin/', ''))
            if (getUserData()) {
                setUserProfile(getUserData().infos_user);
            }
        }, [])
        // console.log('user', user);
    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                    

                    </div>

                    <div className="d-flex align-items-center">

                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-search fs-22"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..."
                                                aria-label="Recipient's username" />
                                            <button className="btn btn-primary" type="submit"><i
                                                className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <span className="d-flex align-items-center">
                                    {/* {userProfile?.photo?(
                                        <img className="rounded-circle header-profile-user" src={userProfile?.photo}
                                        alt="" />
                                    ):( */}
                                        <img className="rounded-circle header-profile-user" src={logo}
                                        alt="" />
                                    {/* )} */}
                                    {/* <img className="rounded-circle header-profile-user" src={userProfile?.photo}
                                        alt="Header Avatar" /> */}
                                    <span className="text-start ms-xl-2">
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user?.user?.name} </span>
                                        {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
                                    </span>
                                </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {(role!==adminRole)&&(

                                    <Dropdown.Item href="/admin/profile">Profil</Dropdown.Item>
                                    )}
                                    <Dropdown.Item href="#" onClick={()=>logout()}>Deconnexion</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                           
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header