export default {
    index:'/index',
    login:'/login',
    histoiques: `/histoiques`,
    demandebyId: `/demand/user/get`,
    demande: `/demande`,
    tracabilite: `/user-actions`,
    userpost: `/user`,
    user: `/user/update`,
    userenable: `/user/enable-disable`,
    userbyId: `/user/get`,
    allfrais: `/frais/get/all`,
    frais: `/frais`,
    demandeIdwith: `/managers/demands`,
    getStat: `/manager/subccount/consult`,
    usersgestionnaire: `/users/managers`,
    postfrais: `/frais`,
    config: `/config`,
    users: `/users/operators`,
    changePasse: `/user/change-password`,
    compteGestionaire: `/user/account/management`,
    validedemande: `/demand/avis/comite/oni`,
    decisiondemande: `/demand/decision/directeur/oni`,
    rejetpartiel: `/reject/demande/partielle`,
    reject: `/reject`,
    postdemande: `/demand`,
    agentbyId: `/users/sub-account`,
    examindemande: `/exam/demandId`,
    statistiqueAdmin: `/statistics/admin`,
    rejetdemande: `/reject/demandId`,
    paiedemande: `/payment/demandId`,
    alldemande: `/demand/all`,
    alldemandeattente: `/demand/list`,
    demandeavisCil: `/examen/notice`,
    demandedecision: `/demand/attente/avis/dg`,
    attenteCil: `/examen/attente/cil`,
    demandeAll: `/demand/all`,
    demandeavisOni: `/demand/avis/comite/oni`,
    postrenouvellement: `/demandes/valider-renouvellement`,
    privileges: `/privileges`,
    configuration: `/configurations`,
    categories: `/categories`,
    examen: `/examen`,
    droit: '/droit-config',
    loginK: 'user-connexion/get-2fa-code',
    logout: '/user-connexion/access/logout',
    loginOni:'https://oni-keyload.switch-maker.net/realms/oni-bf/protocol/openid-connect/token',
  


    handlerError: (error) => _handlerError(error)
}

const _handlerError = (error) => {
    const response = error.response
    if(response) {
        const messageData = response?.data?.messageData
        const message = response?.data?.message
        if(messageData) {
            return getMessage(messageData)
        } else if(message) {
            return getMessage(message)
        } else {
            return response?.statusText
        }
    } else {
        return 'Oops !! Léger souci.'
    }
}

const getMessage = (message = {}) => {
    let _message = '';
    if(typeof message == 'object') {
        for (const key in message) {
            if (message.hasOwnProperty(key)) {
                _message+= ' '+ message[key];
            }
        }
        // _message
    } else {
        _message = message;
    }
    return _message
}