import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PrivateLayout from '../layouts/private';
// import NoMatchPage from '../pages/common/NoMatch';
import { privateRoutes, publicRoutes } from './allRoutes';
// import Loader from '../components/loader';
import { getUserData, getUserRole } from '../utility/Utils';
import RequireAuth from './requireAuth';
import constant from "../utility/constant";
import Loader from '../components/loader';
// import Profile from '../pages/private/profile/profile';
// import Loader from '../components/loader/Loader';


const IndexFront = React.lazy(() => import("../pages/common"));
const Profile = React.lazy(() => import("../pages/private/profile/profile"));

const MainRoutes = () => {
    const userRole = getUserRole()
    const [menuItem, setMenuItem] = useState([]);
    const [menuItemP, setMenuItemP] = useState([]);
    // const [userRole, setuserRole] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const userData = getUserData()

    useEffect(() => {
        setTimeout(() => {
            let _menuItems = privateRoutes.filter(
                (item) =>
                    item.permissions.includes(userRole) ||
                    item.permissions.includes("all")
            );
            setMenuItem(_menuItems);
            setMenuItemP(publicRoutes)
            setIsLoading(false);

        }, 1000)
    }, [menuItem])


    // console.log('an', userData);
    return (
        <>
            {
                isLoading ? (<>
                    <div>
                        <Loader />
                    </div></>) : (<>
                        <React.Suspense >
                            <Routes>

                                <Route element={<PrivateLayout />}>
                                    {menuItem.map((route, index) => {
                                        const ElementPage = route.component;
                                        const permissions = route.permissions;
                                        return (
                                            <Route

                                                path={route.path}
                                                key={index}

                                                element={
                                                    <React.Suspense >

                                                        <RequireAuth>
                                                            {(userData?.user?.mdpDefaut === 'true') ? (
                                                                <Profile />
                                                            ) : (
                                                                <ElementPage />
                                                            )}
                                                        </RequireAuth>
                                                    </React.Suspense>
                                                }
                                            />
                                        )
                                    })}
                                </Route>



                                <Route>

                                    <Route
                                        path="/"
                                        element={
                                            <React.Suspense >
                                                <IndexFront />
                                            </React.Suspense>
                                        }
                                    />

                                </Route>


                            </Routes >
                        </React.Suspense>
                    </>)
            }
        </>
    );
}

export default MainRoutes;
