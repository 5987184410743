import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';

// Import Data
//i18n
// import { withTranslation } from "react-i18next";
import Navdata from './items';
import { delUserData, getUserRole } from '../../utility/Utils';

const VerticalLayout = (props) => {
    const navData = useRef();
    const [stateVariables, setStateVariables] = useState('');
    const itemenus = useRef();
    const [menuItem, setMenuItem] = useState([]);
    const userRole = getUserRole();
    const location = useLocation();

    useEffect(() => {
        navData.current = Navdata().props.children;
        itemenus.current = navData.current;
        if (userRole) {
            let _menuItems = itemenus.current.filter(
                (item) => (
                    item.permissions.includes(userRole) ||
                    item.permissions.includes("all")
                )
            );

            setMenuItem(_menuItems);
        } else {
            delUserData();
            // location.replace('/login')
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, [userRole]);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subItems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    const click = (e, it) => {
        e.preventDefault();
        setStateVariables(stateVariables === it?.id ? '' : it?.id);
        updateIconSidebar(it);
    }

    return (
        <React.Fragment>
            {/* menu Items */}
            {(menuItem || []).map((item, key) => {
                const isActive = stateVariables === item.id;

                return (
                    <React.Fragment key={key}>
                        {/* Main Header */}
                        {item.isHeader ?
                            <li className="menu-title"><span data-key="t-menu">{item.label} </span></li>
                            : (
                                item.subItems ? (
                                    <li className={`nav-item ${(stateVariables == item?.id) ? 'open' : ''}`} >
                                        <Link
                                            onClick={(e) => click(e, item)}
                                            className="nav-link menu-link" 
                                            to={item.link ? item.link : "/#"}
                                            data-bs-toggle="collapse"
                                        >
                                            <i className={item.icon}></i>
                                            <span data-key="t-apps">{item.label}</span>
                                            {item.badgeName ?
                                                <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                                                : null}
                                        </Link>
                                        <Collapse
                                            className="menu-dropdown"
                                            isOpen={stateVariables == item?.id}
                                            id="sidebarApps"
                                        >
                                            <ul className="nav nav-sm flex-column test">
                                                {/* subItems  */}
                                                {item.subItems.map((subItem, key) => {
                                                    const isSubActive = location.pathname === subItem.link;

                                                    return (
                                                        <React.Fragment key={key}>
                                                            {(subItem.permissions.includes(userRole) ||
                                                                subItem.permissions.includes("all")) && (
                                                                <li className={`nav-item ${isSubActive ? 'active' : ''}`}>
                                                                    <Link
                                                                        to={subItem.link ? subItem.link : "/#"}
                                                                        className="nav-link"
                                                                    >
                                                                        {subItem.label}
                                                                        {subItem.badgeName ?
                                                                            <span className={"badge badge-pill bg-" + subItem.badgeColor} data-key="t-new">{subItem.badgeName}</span>
                                                                            : null}
                                                                    </Link>
                                                                </li>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </Collapse>
                                    </li>
                                ) : (
                                    <li className={`nav-item ${location.pathname === item.link ? 'active' : ''}`}>
                                        <Link
                                            className="nav-link menu-link"
                                            to={item.link ? item.link : "/#"}
                                        >
                                            <i className={item.icon}></i> <span>{item.label}</span>
                                            {item.badgeName ?
                                                <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                                                : null}
                                        </Link>
                                    </li>
                                )
                            )
                        }
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default VerticalLayout;
