
import React from 'react';
import { BrowserRouter } from "react-router-dom";
import MainRoutes from './routes';
import './App.css';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <React.StrictMode>
      
    <BrowserRouter>
        <MainRoutes />
        <ToastContainer  transition={Slide}  />

    </BrowserRouter>
</React.StrictMode>
  );
}

export default App;
