import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import constant from "../../utility/constant"

const Navdata = () => {
  const appConstant = { ...constant }
  const role = { ...appConstant?.appRole }
  const menuItems = [

    {
      id: "dashboard",
      icon: "ri-dashboard-line",
      label: "Tableau de bord",
      link: "/admin/dashboard",
      permissions: ['all']
    },
    {
      id: "Demandes",
      label: "Demandes",
      icon: "ri-file-list-3-line",
      link: "/#",
      permissions: ['all'],
      subItems: [
        {
          id: "attente",
          label: "Nouvelles",
          link: "/admin/demandes/en-attentes",
          parentId: "Demandes",
          permissions: ['all'],
        },
        {
          id: "examen",
          label: "Attente de l'avis de la CIL",
          link: "/admin/demandes/attente-avis-cil",
          parentId: "Demandes",
          permissions: ['all'],
        },
        
        {
          id: "avisCommission",
          label: "Attente de l'avis de la commission",
          link: "/admin/demandes/attente-avis-commission",
          parentId: "Demandes",
          permissions: ['all'],
        },
        {
          id: "decisionDG",
          label: "Attente de la décision du DG",
          link: "/admin/demandes/attente-decision-dg",
          parentId: "Demandes",
          permissions: ['all'],
        },
        {
          id: "Valide",
          label: "Attente de création de compte",
          link: "/admin/demandes/validees",
          parentId: "Demandes",
          permissions: ['all'],
        },
        {
          id: "traites",
          label: "Accréditations accordées",
          link: "/admin/demandes/traitee",
          parentId: "Demandes",
          permissions: ['all'],
        },
        {
          id: "all",
          label: "Toutes les demandes",
          link: "/admin/demandes",
          parentId: "Demandes",
          permissions: ['all'],
        },

      ],
    },
    {
      id: 'Utilisateurs',
      icon: 'ri-pages-line',
      label: 'Opérateurs',
      link: '/admin/compteOperations',
      permissions: [role?.admin]
    },
    {
      id: 'gestionnaire',
      icon: 'ri-pages-line',
      label: 'Gestionnaires',
      link: '/admin/gestionnaires',
      permissions: [role?.admin, role?.operateur]
    },
    
   
    {
      id: 'tracabilite',
      icon: 'ri-pages-line',
      label: 'Traçabilités',
      link: '/admin/tracabilite',
      permissions: [role?.admin]
    },
    {
      id: "statistiques",
      icon: "ri-file-list-3-line",
      label: "Statistiques",
      link: "/admin/statistiques",
      permissions: [role?.admin],
    },
    {
      id: "parametre",
      label: "Paramètres",
      icon: "ri-file-list-3-line",
      link: "/#",
      permissions: [role?.admin],
      subItems: [
        {
          id: "privileges",
          label: "Privilèges",
          link: "/admin/privileges",
          parentId: "parametre",
          permissions: [role?.admin],
        },
        {
          id: "categories",
          label: "Catégories",
          link: "/admin/categories",
          parentId: "parametre",
          permissions: [role?.admin],
        },
       
       
        {
          id: "frais",
          label: "Frais de prestations",
          link: "/admin/frais-prestation",
          parentId: "parametre",
          permissions: [role?.admin],
        },
        {
          id: "droit",
          label: "Configuration des propriétes d'identité ",
          link: "/admin/droit-config",
          parentId: "parametre",
          permissions: [role?.admin],
        },
        {
          id: "configuration",
          label: "Configurations globales",
          link: "/admin/configuration",
          parentId: "parametre",
          permissions: [role?.admin],
        },

      ],
    },

  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;